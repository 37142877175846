:root {
  --font-family: "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif;
  --font-color: #1c1e21; /* Updated primary text color */
  --primary-navy: #0d1b2a;
  --primary-gold: #f0a500;
  --accent-teal: #008080;
  --background-color: #f0f2f5;
  --surface-color: #ffffff;
  --secondary-text-color: #606770;
  --error-color: #e53935;
  --success-color: #43a047;
  --divider-color: #e0e0e0;
}
body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
  color: var(--font-color);
  background-color: var(--background-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--font-color);
}

a {
  color: var(--primary-navy);
  text-decoration: none;
}

a:hover {
  color: var(--primary-gold);
}

button {
  cursor: pointer;
  background-color: var(--primary-gold);
  color: var(--surface-color);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
}

button:hover {
  background-color: var(--accent-teal);
}
