/* MessageDetail.module.css */
.messageDetail {
  max-width: 800px;
  margin: 2rem auto;
}

.messageDetail h2 {
  color: var(--primary-navy);
  margin-bottom: 1rem;
}

.messageList {
  border: 1px solid var(--secondary-gray);
  border-radius: 8px;
  padding: 1rem;
  background-color: var(--accent-white);
  max-height: 400px;
  overflow-y: auto;
}

.messageItem {
  margin-bottom: 1rem;
}

.senderName {
  font-weight: bold;
  color: var(--primary-navy);
}

.messageContent {
  margin: 0.5rem 0;
}

.timestamp {
  font-size: 0.8rem;
  color: var(--secondary-gray);
}

.messageInput {
  display: flex;
  margin-top: 1rem;
}

.messageInput textarea {
  flex: 1;
  padding: 0.5rem;
  border: 1px solid var(--secondary-gray);
  border-radius: 4px;
  resize: none;
}

.messageInput button {
  margin-left: 1rem;
  padding: 0.75rem 1.5rem;
  background-color: var(--primary-orange);
  color: var(--accent-white);
  border: none;
  border-radius: 4px;
}

.messageInput button:hover {
  background-color: var(--secondary-teal);
}
